<template>
  <mobile-screen :header="true" screen-class="icon-app1 visits-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <router-link
            v-if="!edit"
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
          <button @click="edit = false" v-else>
            <icon icon="#cx-hea1-edit-cancel" />
          </button>
        </template>
        <div class="component-title">
          {{ displayLabelName("visitor-management", "visit", "visit") }}
        </div>
        <template v-slot:right>
          <button v-if="!edit && !isDisabled" @click="edit = true">
            <icon icon="#cx-hea1-edit" />
          </button>
          <button v-if="edit" @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <!-- Visit details and forms-->
    <VeeForm
      v-if="visit"
      @submit="submitForm"
      ref="editVisitForm"
      v-slot="{ errors }"
      class="form inline-input"
      :initial-values="{
        ...visit
      }"
      novalidate
    >
      <!-- Title -->
      <ul class="clebex-item-section pill no-space" v-if="visit">
        <li class="clebex-item-section-label-inner">
          <span class="highlight">{{
            displayLabelName("visitor-management", "visit", "visit-details")
          }}</span>
        </li>
      </ul>
      <ul class="clebex-item-section" v-if="visit">
        <!-- Date from - date to -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateFromParsed) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-calendar" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateToParsed) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- Hour from - hour to -->
        <li class="clebex-item-section-item time">
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourFrom) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-time" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourTo) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- DateTimePicker -->
        <li class="clebex-item-section-item full-right-underline" v-if="edit">
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateTimePicker"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :rangeMarked="false"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom, selectedHourTo]"
                  :rangeMarked="false"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
        <!-- Host -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="!$route.name.includes('my-visitors')"
        >
          <router-link
            v-if="edit"
            :to="{ name: 'r_reception-desk-edit-visit-select-host' }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("visitor-management", "visit", "host")
              }}</label>
              <p class="text-label ellipsis">
                <template v-if="visit.host">
                  {{ selectedHost }}
                </template>
                <template v-else>
                  {{ displayLabelName("visitor-management", "visit", "host") }}
                </template>
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("visitor-management", "visit", "host")
              }}</label>
              <p class="text-label">
                {{ selectedHost }}
              </p>
            </div>
          </div>
        </li>
        <!-- Notes -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.notes }"
        >
          <span class="error-message" v-if="errors.notes">
            {{ errors.notes }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="notes">
                {{ displayLabelName("visitor-management", "visit", "notes") }}
              </label>
              <Field
                name="notes"
                as="input"
                type="text"
                :disabled="submitting || !edit"
              />
            </div>
          </div>
        </li>
      </ul>
      <!-- End of host details -->
      <button
        type="submit"
        ref="submitVisitForm"
        style="display: none"
      ></button>
    </VeeForm>

    <!-- Meeting details  -->
    <ul
      class="clebex-item-section pill no-space"
      v-if="visit && visit.booking && Object.keys(visit.booking).length"
    >
      <li class="clebex-item-section-label-inner">
        <span class="highlight">{{
          displayLabelName("visitor-management", "visit", "meeting-details")
        }}</span>
      </li>
    </ul>
    <ul
      class="clebex-item-section no-top-margin"
      v-if="visit && visit.booking && Object.keys(visit.booking).length"
    >
      <!-- Meeting Subject  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("visitor-management", "visit", "meeting-subject")
            }}</label>
            <p class="text-label">{{ visit.booking.subject }}</p>
          </div>
        </div>
      </li>

      <!-- End Meeting Subject  -->

      <!-- Meeting Date from  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("visitor-management", "visit", "meeting-from")
            }}</label>
            <p class="text-label">
              {{ dateFormat(visit.booking.datetime_from) }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Date from  -->

      <!-- Meeting Date to  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("visitor-management", "visit", "meeting-to")
            }}</label>
            <p class="text-label">
              {{ dateFormat(visit.booking.datetime_to) }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Date from  -->

      <!-- Meeting Resource  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName(
                "visitor-management",
                "visit",
                "meeting-resource"
              )
            }}</label>
            <p class="text-label">
              {{ visit.booking.resource }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Resource  -->

      <!-- Meeting Organizer  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName(
                "visitor-management",
                "visit",
                "meeting-organizer"
              )
            }}</label>
            <p class="text-label">
              {{ visit.booking.organizer }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Organizer  -->

      <!-- Meeting Description  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName(
                "visitor-management",
                "visit",
                "meeting-description"
              )
            }}</label>
            <p class="text-label">
              {{ visit.booking.description }}
            </p>
          </div>
        </div>
      </li>
      <!-- Meeting Description  -->

      <!-- Meeting Attendies  -->
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="visit.booking.attendies.length"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName(
                "visitor-management",
                "visit",
                "meeting-attendees"
              )
            }}</label>
            <p class="text-label">
              {{
                visit.booking.attendies
                  .map(item => `${item.first_name} ${item.last_name}`)
                  .join(", ")
              }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Attendies  -->
    </ul>
    <!-- End Meeting details  -->

    <!-- History details -->
    <ul
      class="clebex-item-section pill no-space"
      v-if="visit && visit.history && Object.keys(visit.history).length"
    >
      <li class="clebex-item-section-label-inner">
        <span class="highlight">{{
          displayLabelName("visitor-management", "visit", "history-details")
        }}</span>
      </li>
    </ul>

    <ul
      class="clebex-item-section no-top-margin"
      v-if="visit && visit.history && Object.keys(visit.history).length"
    >
      <li
        v-for="(history, index) in visit.history"
        :key="index"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                class="history-status-icon"
                :icon="getHistoryStatus(history.status_id).icon"
              />
            </span>
          </span>
          <dl class="clebex-item-dl no-left-padding">
            <dt class="clebex-item-dt">
              <div class="clebex-item-parent-list">
                <p class="parent-name">
                  {{
                    displayLabelName(
                      "visitor-management",
                      "visit",
                      getHistoryStatus(history.status_id).label
                    )
                  }}
                </p>
              </div>
            </dt>
            <dd class="clebex-item-dd">
              {{ dateFormat(history.datetime) }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <!-- End History details -->
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import { visitStatuses } from "@/services/visitor-management";
import { DateTime } from "luxon";
import { L_VISITS_PAST } from "@/services/consts/modules/visitor-management/visits";

export default {
  name: "Receptions",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    )
  },
  data() {
    return {
      submitting: false,
      edit: false,
      showDateTimePicker: true,
      preselectedTab: "time-slot",
      pillTabs: ["date", "time-slot"],
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedHourFrom: null,
      selectedHourTo: null,
      selectedDateFromParsed: null,
      selectedDateToParsed: null,
      visitorStatuses: [
        {
          id: visitStatuses.CREATED,
          icon: "#cx-vis1-expected",
          label: "created"
        },
        {
          id: visitStatuses.CHECKED_IN,
          icon: "#cx-vis1-check-in",
          label: "checked-in"
        },
        {
          id: visitStatuses.CHECKED_OUT,
          icon: "#cx-vis1-check-out",
          label: "checked-out"
        },
        {
          id: visitStatuses.ABSENT,
          icon: "#cx-vis1-paused",
          label: "absent"
        }
      ]
    };
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  created() {
    this.getVisit({
      visitId: this.visitId,
      receptionDeskId: this.receptionDeskId
    }).then(() => {
      this.selectedDateFrom = DateTime.fromISO(
        this.visit.datetime_from
      ).toFormat("yyyy-dd-MM");
      this.selectedDateTo = DateTime.fromISO(this.visit.datetime_from).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateFromParsed = DateTime.fromISO(
        this.visit.datetime_to
      ).toFormat("yyyy-MM-dd");
      this.selectedDateToParsed = DateTime.fromISO(
        this.visit.datetime_to
      ).toFormat("yyyy-MM-dd");
      this.selectedHourFrom = DateTime.fromISO(
        this.visit.datetime_from
      ).toFormat("HH:mm");
      this.selectedHourTo = DateTime.fromISO(this.visit.datetime_to).toFormat(
        "HH:mm"
      );
    });
  },
  watch: {
    visitId: {
      handler(value) {
        if (typeof value !== "undefined") {
          this.getVisit(this.visitId);
        }
      }
    }
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("visit", ["visit", "visits"]),
    isDisabled() {
      let past = false;
      let hasBookings = false;
      if (this.visits && this.visits[L_VISITS_PAST]) {
        past = this.visits[L_VISITS_PAST].find(v => v.id == this.visitId);
      }
      if (this.visit && this.visit.booking_id) {
        hasBookings = true;
      }
      return past || hasBookings;
    },
    visitId() {
      const { visitId } = this.$route.params;
      return visitId;
    },
    selectedHost() {
      if (
        this.visit.host &&
        this.visit.host.first_name &&
        this.visit.host.last_name
      ) {
        return `${this.visit.host.first_name} ${this.visit.host.last_name}`;
      }
      return null;
    },
    receptionDeskId() {
      const { receptionDeskId } = this.$route.params;
      return receptionDeskId;
    },
    visitorId() {
      const { visitorId } = this.$route.params;
      return visitorId;
    }
  },
  methods: {
    ...mapActions("visit", ["getVisit", "putVisit", "getVisits"]),
    ...mapActions("user", ["getCompanyUserList"]),
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].eventDate;
      } else {
        this.selectedDateTo = this.selectedDateFrom;
        this.selectedDateToParsed = this.selectedDateFromParsed;
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    dateFormat(date) {
      return formatDate(
        date,
        `${this.globalDateFormat} ${this.globalTimeFormat}`
      );
    },
    getHistoryStatus(id) {
      return this.visitorStatuses.find(status => status.id === id);
    },
    initSubmit() {
      this.edit = false;
      this.$refs.submitVisitForm.click();
    },
    submitForm(data) {
      const dateTimeFrom = `${DateTime.fromISO(
        this.selectedDateFromParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourFrom).toFormat(
        "HH:mm"
      )}`;
      const dateTimeTo = `${DateTime.fromISO(
        this.selectedDateToParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourTo).toFormat(
        "HH:mm"
      )}`;
      const payload = {
        datetime_from: dateTimeFrom,
        datetime_to: dateTimeTo,
        host_id: this.visit.host.id,
        reception_desk_id: this.receptionDeskId,
        visit_id: this.visit.id,
        notes: data.notes
      };

      return this.putVisit(payload).then(response => {
        if (response) {
          this.edit = false;
          this.getVisits({
            payload: {
              visitor_id: this.visitorId,
              reception_desk_id: this.receptionDeskId
            }
          });
        }
      });
    }
  }
};
</script>
